<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    :no-content="noContent"
    :go-back-btn="goBack"
    title="Reports History"
  >
    <vbt-table
      :headers="headers"
      :items="items"
    >
      <template #timestamp="{ item: { timestamp } }">
        {{ formatDisplayDate(timestamp) }}
      </template>

      <template #actions="{ item: { id, url, arguments: code } }">
        <v-layout>
          <v-btn
            :disabled="!code"
            title="Show arguments"
            color="info"
            small
            icon
            @click="showArgumentsCode(code)"
          >
            <v-icon>
              remove_red_eye
            </v-icon>
          </v-btn>

          <v-btn
            :href="url"
            target="_blank"
            color="success"
            class="mx-2"
            small
            icon
          >
            <v-icon>
              file_download
            </v-icon>
          </v-btn>

          <v-btn
            color="error"
            small
            icon
            @click="removeReport(id)"
          >
            <v-icon>
              delete
            </v-icon>
          </v-btn>
        </v-layout>
      </template>
    </vbt-table>

    <vbt-pagination
      v-model="pageNum"
      :items-count="pagedItemsCount"
    />

    <vbt-dialog
      v-model="argumentsCodeDialog"
      title="Arguments"
      hide-submit-btn
    >
      <vbt-code
        :value="argumentsCode"
        readonly
      />
    </vbt-dialog>
  </vbt-content-box>
</template>

<script>
import { usePagination } from '@mixins/factories';
import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { ReportsApiService } from '../_services';

const paginationOptions = { itemsName: 'items' };
const headers = Object.freeze([
  { text: 'Login Name', value: 'loginName' },
  { text: 'Type', value: 'type' },
  { text: 'Timestamp', value: 'timestamp' },
  { text: '', value: 'actions', width: '50px' },
]);

export default {
  name: 'IrReportsHistory',

  props: {
    items: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    loadingError: {
      type: Object,
      required: true,
    },
  },

  mixins: [
    usePagination(paginationOptions),
  ],

  data() {
    return {
      argumentsCodeDialog: false,
      argumentsCode: '',

      headers,
    };
  },

  methods: {
    showArgumentsCode(code) {
      this.argumentsCode = JSON.stringify(JSON.parse(code), null, '\t');
      this.argumentsCodeDialog = true;
    },

    removeReport(id) {
      this.$VBlackerTheme.alert.warning({ text: 'Remove report?' }, () => this.wrapToLoadingFn({
        req: ReportsApiService.removeHistoryReportItem.bind(null, { id }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Report successfully removed');
          this.$emit('refresh');
        },
      }));
    },

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>
