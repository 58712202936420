<template>
  <v-menu
    nudge-bottom="25"
    close-delay="50"
    z-index="100"
    open-on-hover
    bottom
    left
  >
    <template #activator="{ on }">
      <v-chip
        small
        v-on="on"
      >
        Report Type:

        <strong
          style="font-size: 13px; margin-right: 5px;"
          class="ml-1 success--text"
        >
          {{ reportType }}
        </strong>

        <v-icon small>
          expand_more
        </v-icon>
      </v-chip>
    </template>

    <v-list max-height="400px">
      <v-list-item
        v-for="type in items"
        :key="type"
        style="background: #1e1e1e;"
        v-show="type !== reportType"
        @click="reportType = type"
      >
        <v-list-item-title class="my-2">
          <v-layout column>
            <strong>
              {{ type }}
            </strong>

            <div
              style="max-width: 400px; white-space: normal;"
              class="grey--text"
            >
              {{ descriptions[type] }}
            </div>
          </v-layout>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { ReportsLocalStorageService } from '../../../_services';

export default {
  name: 'ReportSelector',

  model: {
    prop: 'value',
    type: 'input',
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },

    descriptions: {
      type: Object,
      required: true,
    },
  },

  computed: {
    reportType: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$router.push({ query: { ...this.$route.query, reportType: newValue } });
        ReportsLocalStorageService.addSelectedReportType(newValue);
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
