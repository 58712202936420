<template>
  <v-menu
    nudge-bottom="22"
    close-delay="50"
    open-on-hover
    bottom
  >
    <template #activator="{ on }">
      <v-btn
        style="border-top-left-radius: 0; border-top-right-radius: 0; margin-top: -2px;"
        width="100%"
        depressed
        x-small
        v-on="on"
      >
        Fast filters

        <v-icon
          class="ml-1"
          x-small
        >
          arrow_drop_down
        </v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, i) in config"
        :key="i"
        @click="$emit('input', item.handler())"
      >
        {{ item.title }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  getDatesRangeByLastDays,
  getThisWeekRange,
  getThisMonthRange,
  getLastWeekRange,
  getLastMonthRange,
} from '@helpers';

export default {
  name: 'ReportFastDatesFilter',

  model: {
    prop: 'value',
    type: 'input',
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      config: [
        { title: 'Last 7 days', handler: () => getDatesRangeByLastDays(7) },
        { title: 'Last 30 days', handler: () => getDatesRangeByLastDays(30) },
        { title: 'This week', handler: () => getThisWeekRange() },
        { title: 'This month', handler: () => getThisMonthRange() },
        { title: 'Last week', handler: () => getLastWeekRange() },
        { title: 'Last month', handler: () => getLastMonthRange() },
      ],
    };
  },
};
</script>
