<template>
  <v-layout>
    <v-flex
      v-for="(row, i) in config"
      :key="i"
      class="mr-5"
      sm3
    >
      <div v-if="row.type === 'daterange'">
        <vbt-date-picker
          v-model="form.dates"
          :clearable="false"
          :label="row.label"
          confirm
          range
        />

        <report-fast-dates-filter v-model="form.dates" />
      </div>

      <v-blacker-combobox
        v-if="row.type === 'printCustomerId'"
        v-model="form[row.name]"
        :items="clientsOptions"
        :label="getRowLabel(row)"
      />

      <v-blacker-combobox
        v-if="row.type === 'printProviderId'"
        v-model="form[row.name]"
        :items="providersOptions"
        :label="getRowLabel(row)"
      />

      <v-blacker-combobox
        v-if="row.type === 'accountId'"
        v-model="form[row.name]"
        :items="accountsOptions"
        :label="getRowLabel(row)"
      />

      <v-text-field
        v-if="row.type === 'int'"
        v-model.trim.number="form[row.name]"
        type="number"
        :label="getRowLabel(row)"
      />

      <v-text-field
        v-if="row.type === 'string' || row.type === 'timeSpan'"
        v-model.trim="form[row.name]"
        :label="getRowLabel(row)"
      />

      <v-checkbox
        v-if="row.type === 'boolean'"
        v-model="form[row.name]"
        :label="getRowLabel(row)"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ReportFastDatesFilter from './ReportFastDatesFilter.vue';

export default {
  name: 'ReportForm',

  props: {
    form: {
      type: Object,
      required: true,
    },

    config: {
      type: Array,
      required: true,
    },
  },

  components: {
    ReportFastDatesFilter,
  },

  computed: {
    ...mapGetters('accounts', ['accountsOptions', 'clientsOptions', 'providersOptions']),
  },

  created() {
    this.getAccounts({ activeOnly: false });
  },

  methods: {
    ...mapActions('accounts', ['getAccounts']),

    getRowLabel({ label, optional, type }) {
      return optional || type === 'boolean' ? label : `${label} (required)`;
    },
  },
};
</script>
