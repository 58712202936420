<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        icon
        small
        v-on="on"
        class="ml-3"
      >
        <v-icon>
          help_outline
        </v-icon>
      </v-btn>
    </template>
    {{ description }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'ReportTypeDescription',

  props: {
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
