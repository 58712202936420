<template>
  <v-layout column>
    <ir-report-generator
      :loading="reportsSchemeLoading"
      :config="reportsConfig"
      :types="reportTypes"
      @refresh="resetHistory"
    />

    <ir-reports-search
      ref="reportsSearch"
      :types="reportTypes"
      @search="getReportsHistory"
    />

    <ir-reports-history
      :loading-error="loadingError"
      :items="reportsHistory"
      :loading="loading"
      @refresh="$_fetchData"
    />
  </v-layout>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';
import { useDataLoadingWithIgnoredParams } from '@mixins/factories';
import { dataLoadingState } from '@mixins';

import { generateReportsConfig, generateReportsTypes } from '../_helpers';
import { IrReportGenerator, IrReportsSearch, IrReportsHistory } from '../_components';
import { ReportsApiService } from '../_services';

const dataLoadingOptions = { getterName: 'getReportsHistory', searchable: false, ignoredQueryItems: ['reportType'] };

export default {
  name: 'IrReports',

  components: {
    IrReportGenerator,
    IrReportsSearch,
    IrReportsHistory,
  },

  mixins: [
    dataLoadingState,
    useDataLoadingWithIgnoredParams(dataLoadingOptions),
  ],

  data() {
    return {
      reportsScheme: [],
      reportsHistory: [],
      reportsSchemeLoading: false,
    };
  },

  computed: {
    reportsConfig() {
      return generateReportsConfig(this.reportsScheme);
    },

    reportTypes() {
      return generateReportsTypes(this.reportsScheme);
    },
  },

  created() {
    this.getReportsScheme();
  },

  methods: {
    getReportsHistory(params) {
      this.$_setDataLoadingPending();

      this.wrapToLoadingFn({
        req: ReportsApiService.getReportsHistory.bind({}, params),
        loadingFlagName: dataLoadingState.$_meta.LOADING_FLAG_NAME,
        onSuccess: response => this.$set(this, 'reportsHistory', response),
        onReject: e => this.$_setDataLoadingError(e),
      });
    },

    getReportsScheme() {
      this.wrapToLoadingFn({
        req: ReportsApiService.getReportsScheme,
        loadingFlagName: 'reportsSchemeLoading',
        onSuccess: r => this.$set(this, 'reportsScheme', r),
      });
    },

    resetHistory() {
      // @TODO dirt hack, update load mixins to separated func
      this.$refs.reportsSearch.onResetSearch();
    },

    wrapToLoadingFn,
  },
};
</script>
