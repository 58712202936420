var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('vbt-loading'):(!_vm.inProgressReports.length)?_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" No in progress reports ")]):_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.inProgressReports,"page-size":5,"show-paginator":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var status = ref.item.status;
return [_c('vbt-badge',{attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(status)+" ")])]}},{key:"startedAt",fn:function(ref){
var startedAt = ref.item.startedAt;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(startedAt))+" ")]}},{key:"stageProgress",fn:function(ref){
var stageProgress = ref.item.stageProgress;
return [_c('v-progress-linear',{attrs:{"value":stageProgress,"color":"warning","height":"20","striped":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true)})]}},{key:"overallProgress",fn:function(ref){
var overallProgress = ref.item.overallProgress;
return [_c('v-progress-linear',{attrs:{"value":overallProgress,"color":"success","height":"20","striped":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true)})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.cancelLoadings[item.reportId],"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.cancelReportGeneration(item.reportId)}}},on),[_c('v-icon',[_vm._v(" cancel ")])],1)]}}],null,true)},[_vm._v(" Cancel ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }