import { useDataLoading } from '@mixins/factories';
import { createTicker, formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { ReportsApiService, ReportsLocalStorageService } from '../../../../_services';

const dataLoadingOptions = {
  getterName: 'refreshInProgressQueue',
  searchable: false,
  paged: false,
};
const InProgressReportsTicker = createTicker();
const headers = Object.freeze([
  { text: 'Status', value: 'status' },
  { text: 'Type', value: 'type' },
  { text: 'Started At', value: 'startedAt' },
  { text: 'Stage', value: 'stage' },
  { text: 'Stage Progress', value: 'stageProgress' },
  { text: 'Overall Progress', value: 'overallProgress' },
  { text: '', value: 'actions', width: '10px' },
]);

export default {
  name: 'InProgressReports',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  data() {
    return {
      inProgressReports: [],
      stoppedTicker: false,
      cancelLoadings: {},
      headers,
    };
  },

  created() {
    document.addEventListener('refresh-after-report-success', this.refreshInProgressQueue);
    this.runTicker();
  },

  beforeDestroy() {
    document.removeEventListener('refresh-after-report-success', this.refreshInProgressQueue);
    InProgressReportsTicker.destroyTicker();
    this.removeFinishedReportsFromQueue();
  },

  methods: {
    runTicker() {
      InProgressReportsTicker.loadTicker({
        req: () => this.refreshInProgressQueue(),
        intervalMSec: 5000,
      });
    },

    refreshInProgressQueue() {
      return this.wrapToLoadingFn({
        req: ReportsApiService.getInProgressReports,
        withLoadingSpinner: false,
        onSuccess: (r) => {
          this.$set(this, 'inProgressReports', r);
          this.refreshReportsHistory();

          if (!r.length) {
            InProgressReportsTicker.stopTicker();
            this.stoppedTicker = true;
          } else if (this.stoppedTicker) {
            this.runTicker();
            this.stoppedTicker = false;
          }
        },
      });
    },

    refreshReportsHistory() {
      const ids = ReportsLocalStorageService.getReportsIdsInProgress();
      const needRefresh = ids.reduce((acc, id) => {
        // eslint-disable-next-line no-underscore-dangle
        let _acc = acc;

        if (!this.inProgressReports.find(({ reportId }) => reportId === id)) {
          ReportsLocalStorageService.removeReportIdFromInProgress(id);
          _acc = _acc || true;
        }

        return _acc;
      }, false);

      if (needRefresh) {
        this.$emit('refresh');
      }
    },

    cancelReportGeneration(reportId) {
      this.$VBlackerTheme.alert.warning({ text: 'Cancel report generation?' }, async () => {
        this.$set(this.cancelLoadings, reportId, true);

        try {
          await ReportsApiService.cancelReportGeneration(reportId);
          this.$VBlackerTheme.notification.success('Successfully removed');
          this.refreshInProgressQueue();
        } catch (e) {
          this.$VBlackerTheme.notification.error(e);
        } finally {
          delete this.cancelLoadings[reportId];
        }
      });
    },

    removeFinishedReportsFromQueue() {
      const ids = ReportsLocalStorageService.getReportsIdsInProgress();

      ids.forEach((id) => {
        if (!this.inProgressReports.find(({ reportId }) => reportId === id)) {
          ReportsLocalStorageService.removeReportIdFromInProgress(id);
        }
      });
    },

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
