var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-content-box',{attrs:{"loading":_vm.loading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Reports History"}},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"timestamp",fn:function(ref){
var timestamp = ref.item.timestamp;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(timestamp))+" ")]}},{key:"actions",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var url = ref_item.url;
var code = ref_item.arguments;
return [_c('v-layout',[_c('v-btn',{attrs:{"disabled":!code,"title":"Show arguments","color":"info","small":"","icon":""},on:{"click":function($event){return _vm.showArgumentsCode(code)}}},[_c('v-icon',[_vm._v(" remove_red_eye ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"href":url,"target":"_blank","color":"success","small":"","icon":""}},[_c('v-icon',[_vm._v(" file_download ")])],1),_c('v-btn',{attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.removeReport(id)}}},[_c('v-icon',[_vm._v(" delete ")])],1)],1)]}}])}),_c('vbt-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}}),_c('vbt-dialog',{attrs:{"title":"Arguments","hide-submit-btn":""},model:{value:(_vm.argumentsCodeDialog),callback:function ($$v) {_vm.argumentsCodeDialog=$$v},expression:"argumentsCodeDialog"}},[_c('vbt-code',{attrs:{"value":_vm.argumentsCode,"readonly":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }